export const keys = [
    { val: "AC", cls: "button action-button" },
    { val: "MC", cls: "button action-button" },
    { val: "+/-", cls: "button action-button" },
    { val: "*", cls: "button calc-action-button" },
    { val: 7, cls: "button num-button" },
    { val: 8, cls: "button num-button" },
    { val: 9, cls: "button num-button" },
    { val: "+", cls: "button calc-action-button" },
    { val: 4, cls: "button num-button" },
    { val: 5, cls: "button num-button" },
    { val: 6, cls: "button num-button" },
    { val: "-", cls: "button calc-action-button" },
    { val: 1, cls: "button num-button" },
    { val: 2, cls: "button num-button" },
    { val: 3, cls: "button num-button" },
    { val: "/", cls: "button calc-action-button" },
    { val: 0, cls: "button num-button zero" },
    { val: ".", cls: "button num-button" },
    { val: "=", cls: "button calc-action-button" },
  ];
